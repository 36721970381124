body {
  padding: 1rem;
  background-color: #081a39;
  color: white;
}

* {
  box-sizing: border-box;
}

/* hide scrollbar */
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-button {
  width: 1px;
  height: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "QTVagaRound", sans-serif !important;
}

a {
  color: white;
  transition: all 0.4s;
}

a:visited {
  color: #aaa;
}

a:hover {
  color: #1ec5da;
}

footer {
  justify-self: end;
  margin-top: auto;
}

.App {
  align-items: middle;
  text-align: center;
  background-color: #081a39;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-evenly !important;
}

.section {
  overflow: hidden !important;
}
/* Shifting these two divs down a bit makes those two section's
    content clear the navbar*/
.section:nth-of-type(2) > div {
  padding-top: 4rem;
}

.section:nth-of-type(5) > div {
  padding-top: 4rem;
}

.preloader {
  height: auto;
  margin: calc(50vh - 100px) auto;
  max-width: 100px;
}

/******** NAV MENU ********/
header {
  display: flex;
  background-color: #081a39;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9997;
  max-width: 100%;
}

nav {
  display: flex;
  align-items: center;
  background-color: #081a39;
  justify-content: end;
  width: 100vw;
  border: none;
  padding: 0.5rem 1rem 0.75rem;
  max-width: 100vw;
}

nav a {
  color: #fff;
  margin-right: 1rem;
  text-decoration: none;
  z-index: 9999;
}

nav button {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 9999;
  position: relative;
  margin-top: 0.5rem;
  transition: scale 3s ease-in-out;
}

nav button:hover {
  transform: scale(1.1);
}

form button {
  transition: all 0.5s ease !important;
}

form button:hover {
  background-color: #4f236d !important;
}

.menu {
  position: fixed;
  max-width: 100vw;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background-color: #081a39;
  z-index: 9997;
  overflow-x: hidden;
  transition: 0.5s;
}

.menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}

.nav-brand {
  transition: all 1s ease;
}

.nav-brand:hover {
  transform: scale(1.25);
}

.show {
  width: 100%;
  background-color: #642d8b;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: right;
  margin-top: 5rem;
  margin-right: 1rem;
}

.menu li {
  margin-bottom: 1rem;
  font-family: "QTVagaRound";
  font-size: 2.5rem;
  font-weight: bold;
}

.menu li a {
  display: block;
  padding: 1rem;
  text-decoration: none;
  color: #fff;
}

.auth-button {
  background-color: #081a39;
  border-radius: 10px;
  font-family: "QTVagaRound";
  font-size: 2rem;
  font-weight: bold;
  padding: 0.5rem;
  min-width: 90%;
  max-height: 3.5rem;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
}

.auth-button:hover {
  background-color: #0a2b5a;
}

.avatar-icon {
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  left: 5px;
  top: 3px;
  position: absolute;
  height: 3rem;
  width: 3rem;
}

.logo-mobile {
  width: 50%;
  animation: popin 0.5s ease;
}

.App p {
  width: 85%;
  text-aligh: center;
  margin: 0 auto;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-button {
  position: fixed;
  display: flex;
  z-index: 99 !important;
  bottom: 0;
  right: 0;
}

.card {
  margin: 1rem;
  padding: 1rem;
  padding-bottom: 1rem !important;
  display: flex;
  align-items: center;
}

.card p {
  text-align: center;
  margin: 1rem auto;
}

.mega-heading {
  font-size: 2.5rem;
  display: flex;
  text-align: left;
  margin: 1rem auto;
}

.section-heading {
  font-size: 3rem;
}

.glass {
  backdrop-filter: blur(16px) saturate(104%);
  -webkit-backdrop-filter: blur(16px) saturate(104%);
  background-color: rgba(8, 26, 57, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.glass:nth-of-type(2) {
  margin-bottom: 3rem;
  padding-top: 2rem;
}

.arrow-gradient {
  animation: 3s infinite pulse;
  transition: all 0.4s;
}

.arrow-gradient:hover {
  transform: scale(1.05);
}

.section-two-flex {
  flex-direction: column;
}

.pricing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pricing-section p {
  margin: 0 2rem 2rem;
}

.pricing-cards {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.pricing-card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 1rem 0.5rem;
  padding: 16px;
  width: 25%;
}

.pricing-card a button {
  width: 100%;
}

.pricing-card h3 {
  font-size: 24px;
  margin-bottom: 16px;
}

.pricing-card ul {
  list-style: none;
  margin: 0 0 1.5rem;
  padding: 0;
}

.pricing-card .fas {
  margin-right: 1rem;
  color: green;
}

.pricing-card ul li {
  text-align: left;
  margin-bottom: 8px;
}

.pricing-card span {
  margin-top: auto !important;
}

.price-tag {
  font-size: 36px;
  font-weight: bold;
  margin: 0 auto !important;
}

.cta-button {
  background-color: #081a39;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.cta-button:hover {
  background-color: #0a2b5a;
}

footer {
  align-items: center;
  background-color: #081a39;
  border-top: 1px solid #e7e7e755;
  color: white;
  display: flex;
  min-height: 12rem;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  padding: 32px;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  width: 100%;
}

.footer-content-tl {
  margin: 2rem 0 1rem;
  justify-content: center;
}

.footer-content-br {
  margin: 1rem 0;
  justify-content: center;
  flex-direction: column;
}

.footer-content a {
  margin: 3px 0;
}

@media (max-width: 1280px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }
  .pricing-card {
    width: 100%;
  }
}

/******* SHOWCASE ********/
.showcase-section {
  margin: 2rem 0 1rem;
}

.showcase-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcase-list img {
  max-width: 100%;
  margin: 1rem;
  border-radius: 5%;
}

@media (min-width: 768px) {
  .showcase-section {
    margin: 1rem 0;
  }

  .showcase-section h1 {
    text-align: center;
  }

  .showcase-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .showcase-list img {
    max-width: 500px;
    margin: 2rem;
  }
}

.external {
  overflow: hidden;
  width: 100vw;
  margin-left: -4rem;
  max-height: 85vh;
}

.horizontal-scroll-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vh;
  transform: rotate(-90deg) translate3d(0, -100vh, 0);
  transform-origin: right top;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  height: 100vw;
  perspective: 1px;
  transform-style: preserve-3d;
  padding: 10rem 0;
}
.img-wrapper {
  transform: rotate(90deg);
  display: flex;
  margin: 4rem 0;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  transform: rotate(90deg) translateZ(0.1px) scale(0.9) translateX(0px)
    translateY(-3vh);
  transition: 1s;
}

.img-wrapper img {
  max-height: 300px;
  width: auto;
  transition: all 1s ease;
}

.img-wrapper img:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.slower {
  transform: rotate(90deg) translateZ(-0.2px) scale(1.1) translateX(0%)
    translateY(-10vh);
}
.slower1 {
  transform: rotate(90deg) translateZ(-0.25px) scale(1.05) translateX(0%)
    translateY(8vh);
}
.slower2 {
  transform: rotate(90deg) translateZ(-0.3px) scale(1.3) translateX(0%)
    translateY(2vh);
}
.slower-down {
  transform: rotate(90deg) translateZ(-0.2px) scale(1.1) translateX(0%)
    translateY(16vh);
}
.faster {
  transform: rotate(90deg) translateZ(0.15px) scale(0.8) translateX(0%)
    translateY(14vh);
}
.faster1 {
  transform: rotate(90deg) translateZ(0.05px) scale(0.8) translateX(0%)
    translateY(10vh);
}
.fastest {
  transform: rotate(90deg) translateZ(0.22px) scale(0.7) translateX(-10vh)
    translateY(-15vh);
}
.vertical {
  transform: rotate(90deg) translateZ(-0.15px) scale(1.15) translateX(0%)
    translateY(0%);
}
.last {
  transform: rotate(90deg) translateZ(-0.2px) scale(1.1) translateX(25vh)
    translateY(-8vh);
}

.img-lg img {
  max-height: 450px !important;
}

.img-sm img {
  max-height: 250px !important;
}

/* Legal and other non-fullpage pages */
.static-page {
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 95vh;
  padding-top: 2rem;
}

.static-page ul {
  font-size: 0.9rem;
  margin: 1rem auto 2rem 0;
  max-width: 75%;
  text-align: left;
  font-style: italic;
}

.static-page li {
  margin: 0.5rem 0;
}

.section-paragraph {
  margin-bottom: 4rem;
  padding: 0 2rem;
}

/******* PROFILE PAGE ******/
.profile-card {
  align-items: center;
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 8rem auto;
  padding: 1rem;
  text-align: center;
  width: 80%;
}

.avatar {
  border-radius: 100%;
  height: auto;
  width: 9rem;
}

.invoice-bar {
  background-color: #0f316b;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 5px;
  transition: all 1s ease;
}

.badge {
  border: 1px solid;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  opacity: 0.8;
  padding: 3px;
  width: 48px;
}

.paid {
  background-color: #6d9f71;
}

.open {
  background-color: #36558f;
}

.void {
  background-color: #40376e;
}

.invoice-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.invoice-actions {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.invoice-actions a {
  margin-right: 1rem;
}

.toggle-details {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle-details .chevron {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  margin-left: 0.5rem;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}

.toggle-details .chevron.up {
  transform: rotate(135deg);
  top: 0;
}

.line-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
  padding: 0 1.5rem 1rem;
}

.line-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;
}

.line-description {
  font-family: "QTVagaRound", sans-serif !important;
  font-weight: bold;
  font-size: 1.25rem;
}

.line-amount {
  font-family: "QTVagaRound", sans-serif !important;
  font-weight: bold;
  font-size: 1.25rem;
}

.line-dots {
  font-family: "QTVagaRound", sans-serif !important;
  font-weight: bold;
  font-size: 1.25rem;
  position: absolute;
  left: 45%;
  display: none;
}

.invoice-total {
  align-self: flex-end;
  font-family: "QTVagaRound", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1.5rem;
}

/******* 3CX WIDGET ********/
.call-us {
  position: fixed;
  font-size: 16px;
  line-height: 17px;
  z-index: 99999;
  --call-us-main-accent-color: #3397d4;
  --call-us-main-background-color: #ffffff;
  --call-us-plate-background-color: #373737;
  --call-us-plate-font-color: #e6e6e6;
  --call-us-main-font-color: #292929;
  --call-us-agent-bubble-color: #29292910;
  right: 20px;
  bottom: 20px;
}

/***** FULLPAGE.JS OVERRIDES *****/
/*Overrides must match specificity of pre-loaded styles!*/
/*The simplest way to get the selector is with devtools*/
#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: white;
}

#fp-nav > ul > li > a > span:nth-child(2) {
  background-color: white;
}

#fp-nav > ul {
  background-color: #081a39;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  border-radius: 5px;
}

/***** ANIMATIONS & MEDIA QUERIES *****/
@keyframes slidein {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes pulse {
  0% {
    opacity: 60%;
  }
  50% {
    opacity: 100%;
  }
  100% {
    opacity: 60%;
  }
}

@keyframes popin {
  0% {
    transform: scale(0.1);
  }
  95% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (min-width: 461px) {
  .show {
    width: 66.66%;
  }
  nav button {
    margin-right: 1.5rem;
  }
  .profile-card {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .line-dots {
    display: flex;
  }
  .pricing-section {
    flex-direction: column;
  }
  .profile-card {
    width: 75%;
  }
}

@media (min-width: 1281px) {
  body {
    padding: 4rem;
  }
  footer {
    padding: 32px 6rem;
    min-height: 96px;
  }
  nav button {
    margin-right: 1.5rem;
  }
  .auth-button {
    min-width: 50%;
  }
  .show {
    width: 33.33%;
  }
  .section-two-flex {
    flex-direction: row;
  }
  .footer-content-br {
    justify-content: end;
    margin-top: 5px;
  }
  .mega-heading {
    font-size: 4.5rem;
  }
  .glass {
    max-width: 33%;
  }
  .glass:nth-of-type(1) {
    margin-left: 4rem;
    max-width: 50%;
    padding: 0;
  }
  .glass:nth-of-type(2) {
    margin-left: auto;
    margin-right: 3rem;
  }
  .section-two-flex {
    flex-direction: row;
  }
  .form-widget {
    margin: 2rem 0 0 1.5rem;
  }
  .profile-card {
    width: 50%;
  }
  .badge {
    width: 64px;
  }
}

@media (min-width: 1921px) {
  .section-two-flex {
    flex-direction: column;
  }

  .section-heading {
    font-size: 7rem;
  }

  .profile-card {
    width: 40%;
    margin: 1rem 0 2rem;
  }
}
